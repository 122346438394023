import { AuthGuard } from './../guard/auth.guard';
import { NgModule } from '@angular/core';

import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NewloginComponent } from './newlogin/newlogin.component';

const routes: Routes = [
  
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  { path: '', component: NewloginComponent },
  { path: 'signin', component: NewloginComponent },
  // {
  //   path: 'signup',
  //   loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  // },
  {
    path: 'setting',
    loadChildren: () => import('./pages/setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  // {
  //   path: 'signin',
  //   loadChildren: () => import('./signin/signin.module').then( m => m.SigninPageModule)
  // },
  // {
  //   path: 'welcome',
  //   loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule),
  //   data: { preload: true }, canLoad: [AuthGuard] 
  // },
  {
    path: 'main-home', 
    loadChildren: () => import('./pages/aggrid/aggrid.module').then( m => m.AggridPageModule)
      //  loadChildren: () => import('./main-home/main-home.module').then( m => m.MainHomePageModule),    data: { preload: true }, canLoad: [AuthGuard] 
  },
  {
    path: 'aggrid',
    loadChildren: () => import('./pages/aggrid/aggrid.module').then( m => m.AggridPageModule)
  },
  {
    path: 'defailinfo/:stock/:qty',
    loadChildren: () => import('./pages/defailinfo/defailinfo.module').then( m => m.DefailinfoPageModule)
  },

  // {
  //   path: 'changepassword',
  //   loadChildren: () => import('./changepassword/changepassword.module').then( m => m.ChangepasswordPageModule) , data: { preload: true }, canLoad: [AuthGuard] 
  // },
  {
    path: 'index',
    loadChildren: () => import('./pages/index/index.module').then( m => m.IndexPageModule)
  },
  {
    path: 'analytical',
    loadChildren: () => import('./pages/stocks/stocks.module').then( m => m.StocksPageModule)
  },
  {
    path: 'analytical/:para',
    loadChildren: () => import('./pages/stocks/stocks.module').then( m => m.StocksPageModule)
  },

  {
    path: 'hedge',
    loadChildren: () => import('./pages/hedge/hedge.module').then( m => m.HedgePageModule)
  },
  {
    path: 'sharedata',
    loadChildren: () => import('./pages/sharedata/sharedata.module').then( m => m.SharedataPageModule)
  },
  {
    path: 'analyticaldtl',
    loadChildren: () => import('./pages/stockdetails/stockdetails.module').then( m => m.StockdetailsPageModule)
  },
  {
    path: 'addst',
    loadChildren: () => import('./pages/add/add.module').then( m => m.AddPageModule)
  },
  {
    path: 'Anyst/:stock',
    loadChildren: () => import('./pages/detail/detail.module').then( m => m.DetailModule)
  },
  // {
  //   path: 'Chart',
  //   loadChildren: () => import('./spending-month/spending-month.module').then( m => m.SpendingMonthPageModule)
  // },
 
 
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }



// { path: 'add-accounts/:id',   component: AccountsComponent, canLoad: [AuthGuard]  },
// { path: 'add-accounts/new',   component: AccountsComponent, canLoad: [AuthGuard]  },