import { Location } from '@angular/common';
import { Component, OnInit , ElementRef, ViewChild} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ApiService } from './../../service/api.service';
import { Settings } from './../../service/settings/settings';
import * as $ from "jquery";
import * as CryptoJS from 'crypto-js';
import { Storage } from '@ionic/storage';
import { AlertController, NavController } from '@ionic/angular';
import { JoyrideOptions } from 'ngx-joyride/lib/models/joyride-options.class';
import { JoyrideService } from 'ngx-joyride';

@Component({
  selector: 'app-newlogin',
  templateUrl: './newlogin.component.html',
  styleUrls: ['./newlogin.component.scss'],
})
export class NewloginComponent implements OnInit {

  @ViewChild('endtour', { read: ElementRef})  btn: ElementRef;

  isLoading = false;
  result: any;
  isLoggedIn: boolean;
  public userloggedin: boolean = false;
  isadd: Boolean = false;
  counter = 10;

  constructor(private location: Location, private storage: Storage, public api: ApiService,
    private _Router: Router, public settings: Settings,
    private navCtrl: NavController
    , public altertCtl: AlertController,
    private alertController: AlertController,
    private readonly joyrideService: JoyrideService
  ) {


  }

  ngOnInit() {
    this.storage.get('sellerclient').then((val) => {
      var objtemp = $.parseJSON(val);
      if (typeof (objtemp) !== 'undefined' && objtemp !== null) {
        this.navCtrl.navigateRoot('/analytical/all');
      }
    });
  }

  passwordType: string = 'password';
  passwordIcon: string = 'eye-off';

  hideShowPassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }


  //this.presentAlert
  starttour() {
    const options: JoyrideOptions = {
      steps: ['step1', 'step2', 'step3','step4'],
      themeColor: '#000000',
      showCounter: false
    };
    this.joyrideService.startTour(options);
  }

  scrolltoView()
  {
    this.btn.nativeElement.scrollIntoView({behaviour: 'auto', block: 'start'});
  }


  async presentAlert(strheader: string, strmessage: string) {
    const alert = await this.altertCtl.create({
      header: strheader,
      message: strmessage,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }
      ]
    });
    await alert.present();
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    const email = form.value.email;
    const user_pass = form.value.user_pass;

    if(email == 'admin' && user_pass == 'admin1')
    {
      let user: { user_id: string } = {
        user_id: 'admin'
      };
      this.storage.set('sellerclient', JSON.stringify(user));
      this.navCtrl.navigateRoot('/analytical/all');
    }
    else
    {
      this.presentAlert('Authentication', 'Invalid Credentials');
    }
  }


  bin2hex(bin) {
    var i = 0, l = bin.length, chr, hex = ''
    for (i; i < l; ++i) {
      chr = bin.charCodeAt(i).toString(16)
      hex += chr.length < 2 ? '0' + chr : chr
    }
    return hex
  }

  backClick() {
    this.location.back();
  }

}
