import { Injectable , Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import * as $ from "jquery";
import { Storage } from '@ionic/storage';

export enum ConnectionStatus {
  Online,
  Offline
}
// const { Network } = Plugins;
@Injectable()


export class Settings {
  // networkStatus: NetworkStatus;
  // networkListener: PluginListenerHandle;

  public _isuserloggedin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public _isEnglish: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public _loggedinusername: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public _loggedinuseremail: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public _loggedinuserid: BehaviorSubject<string> = new BehaviorSubject<string>('0');
  public _loggedinuserContactno: BehaviorSubject<string> = new BehaviorSubject<string>('0');
  public _isSuperUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public _Cartcount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public _currentstoreid: BehaviorSubject<string> = new BehaviorSubject<string>('0');
  public _currentstorecompany: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public _currentstoremailid: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public _currentstoraddress: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public _adminstoreid: BehaviorSubject<string> = new BehaviorSubject<string>('0');
  public _adminstorecompany: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public _adminstoremailid: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public _adminstoraddress: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public netconnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public nettype: BehaviorSubject<string> = new BehaviorSubject<string>('');
  
  public _imagebasepath = 'http://ghaya.mhds.co.in/';
  constructor(protected localStorage: Storage) {
    //this.getnetworkstatus()
    this.getloginstatus();
    this.getstorestatus();
  }


  getloginstatus(): boolean {
    let status: boolean = false;
    this.localStorage.get('sellerclient').then((val) => {
      var objtemp = $.parseJSON(val);
      if (typeof (objtemp) !== 'undefined' && objtemp !== null) {
        this._isuserloggedin.next(true);
        this._loggedinusername.next(objtemp.firstname);
        this._loggedinuserContactno.next(objtemp.contactno1);
        this._loggedinuserid.next(objtemp.user_id);
        this._loggedinuseremail.next(objtemp.email);

        status = true;
      } else {
        this._isuserloggedin.next(false);
        this._loggedinusername.next('');
        this._loggedinuserContactno.next('0');
        status = false;
      }
    });
    return status;
  }

  getstorestatus(): boolean {
    let status: boolean = false;
    this.localStorage.get('franchise_store').then((val) => {
      var objtemp = $.parseJSON(val);
      if (typeof (objtemp) !== 'undefined' && objtemp !== null) {
        this._currentstoreid.next(objtemp.adminuser_id);
        this._currentstorecompany.next(objtemp.company_name);
        this._currentstoremailid.next(objtemp.email);
        this._currentstoraddress.next(objtemp.address);

        if (objtemp.usertype == "admin") {
          this._adminstoreid.next(objtemp.adminuser_id);
          this._adminstorecompany.next(objtemp.company_name);
          this._adminstoremailid.next(objtemp.email);
          this._adminstoraddress.next(objtemp.address);
        }

        status = true;
      }
      else {
        this._currentstoreid.next('0');
        this._currentstorecompany.next('');
        this._currentstoremailid.next('');
        this._currentstoraddress.next('');

        this._adminstoreid.next('0');
        this._adminstorecompany.next('');
        this._adminstoremailid.next('');
        this._adminstoraddress.next('');
        status = false;
      }
    });
    return status;
  }

  setLoginStatus(isLoggedIn: boolean) {
    this.setuserparameter(isLoggedIn);
  }

  setcount(cartcount) {
    this._Cartcount.next(cartcount);
  }

  setusername(usernm: string) {
    this._loggedinusername.next(usernm);
  }

  setuserparameter(set: boolean) {
    if (set) {
      this.localStorage.get('sellerclient').then((val) => {
        var objtemp = $.parseJSON(val);

        if (typeof (objtemp) !== 'undefined' && objtemp !== null) {
          this._isuserloggedin.next(true);
          this._loggedinusername.next(objtemp.firstname);
          this._loggedinuserContactno.next(objtemp.contactno1);
          this._loggedinuserid.next(objtemp.user_id);
          this._loggedinuseremail.next(objtemp.email);
          //      this._Cartcount.next(objtemp.cartcount);
        }
      });
    } else {
      this._isuserloggedin.next(false);
      this._loggedinusername.next('');
      this._loggedinuserid.next('');
      this._loggedinuserContactno.next('0');
      this._loggedinuseremail.next('');
      //  this._Cartcount.next('');
    }
  }

  setuserparameter2(set: boolean): boolean {
    let status: boolean = false;
    if (set) {
      this.localStorage.get('sellerclient').then((val) => {
        var objtemp = $.parseJSON(val);
        if (typeof (objtemp) !== 'undefined' && objtemp !== null) {
          this._isuserloggedin.next(true);
          this._loggedinusername.next(objtemp.firstname);
          this._loggedinuserid.next(objtemp.user_id);
          this._loggedinuseremail.next(objtemp.email);
          // this._Cartcount.next(objtemp.cartcount);
        }
      });
      status = true;
    }
    else {
      this._isuserloggedin.next(false);
      this._loggedinusername.next('');
      this._loggedinuserid.next('');
      this._loggedinuseremail.next('');
      // this._Cartcount.next('');
      status = true;
    }
    return status;
  }


  setstoreparmeter(set: boolean): boolean {
    let status: boolean = false;
    if (set) {
      this.localStorage.get('franchise_store').then((val) => {
        var objtemp = $.parseJSON(val);
        if (typeof (objtemp) !== 'undefined' && objtemp !== null) {

          this._currentstoreid.next(objtemp.adminuser_id);
          this._currentstorecompany.next(objtemp.company_name);
          this._currentstoremailid.next(objtemp.email);

          if (objtemp.usertype == "admin") {
            this._adminstoreid.next(objtemp.adminuser_id);
            this._adminstorecompany.next(objtemp.company_name);
            this._adminstoremailid.next(objtemp.email);
          }

        }
      });
      status = true;
    }
    else {
      this._currentstoreid.next('0');
      this._currentstorecompany.next('');
      this._currentstoremailid.next('');

      this._adminstoreid.next('0');
      this._adminstorecompany.next('');
      this._adminstoremailid.next('');


      status = true;
    }
    return status;
  }

  clearuserparameters() {
    this._isuserloggedin.next(false);
    this._loggedinusername.next('');
    this._loggedinuserid.next('');
    this._loggedinuseremail.next('');
    //  this._Cartcount.next('');
  }

  ConvertStringToNumber(input: string) {
    if (input.trim().length == 0) {
      return NaN;
    }
    return Number(input);
  }

}

