import { Injectable } from '@angular/core';
import { Router,Route, CanActivate,CanLoad, ActivatedRouteSnapshot, RouterStateSnapshot,UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import * as $ from "jquery";
import { Storage } from '@ionic/storage';
import { Settings } from './../service/settings/settings';
import { NavController } from '@ionic/angular';

@Injectable()
export class AuthGuard implements CanLoad {
  constructor(private router: Router, protected localStorage: Storage, public settings: Settings,private navCtrl: NavController) { }
  public userloggedin: boolean = false;
  public istrue: boolean = false;
  public temp: any;

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      return this.localStorage.get('sellerclient')
        .then((val) => {
          console.log('val');
          console.log(val);
          this.temp = $.parseJSON(val);
          if (typeof (this.temp) == 'undefined' || this.temp == null) {
            //this.router.navigateByUrl('/login');
            this.navCtrl.navigateRoot('/signin');
          }
          else {
            resolve(true);
          }
        });
    })
  }

  // canActivate(): Promise<boolean> {

  //   return new Promise((resolve, reject) => {
  //     return this.localStorage.get('sellerclient')
  //     .then((val) => {
  //       this.temp = $.parseJSON(val);
  //       if (typeof (this.temp) == 'undefined' || this.temp == null) {
  //         this.router.navigate(['/login']);
  //       }
  //       else
  //       {
  //         resolve(true);
  //       }
  //     });
  //   })
  // }

  // canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {

  //   this.settings._isuserloggedin.subscribe(value => this.userloggedin = value);

  //   if (this.userloggedin)
  //   {
  //     return true;
  //   }
  //   else
  //   {
  //     this.router.navigate(['/login']);
  //     return false;
  //   }

  // }



  // canActivate(route: ActivatedRouteSnapshot): boolean {
  //   this.settings._isuserloggedin.subscribe(value => this.userloggedin = value);
  //   this.localStorage.get('sellerclient').then((val) => {
  //     this.temp = $.parseJSON(val);
  //     if (typeof (this.temp) == 'undefined' || this.temp == null) {
  //       this.router.navigate(['/login']);
  //       return false;
  //     }
  //   });
  //   return true;
  // }
  //   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

  //     this.localStorage.get('sellerclient').then((val) => {
  //       this.temp = $.parseJSON(val);

  //     });

  //     if (typeof (this.temp) !== 'undefined' && this.temp !== null) {
  //       this.istrue = true;
  //       return false;
  //     }
  //     else{
  //       this.istrue = false;
  //       this.router.navigate(['/login']);
  //       return false;
  //     }

  // }

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) 
  // {
  //   let status: boolean = false;
  //   this.localStorage.get('sellerclient').then((val) => {
  //     var objtemp = $.parseJSON(val);
  //     if (typeof (objtemp) !== 'undefined' && objtemp !== null) {
  //       status = true;
  //     }
  //     else {
  //     }
  //   });

  //   if (status) {
  //     return true;
  //   }
  //   else {
  //     this.istrue = false;
  //     //alert('Please Login to proceed');
  //     this.router.navigate(['login']);
  //     return false;
  //   }
  // }

  

}
