import { AuthGuard } from './../guard/auth.guard';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
//import { Settings } from './../service/settings/Settings';
import { ApiService } from './../service/api.service';
import { Settings } from 'src/service/settings/settings';
import { IonicStorageModule } from '@ionic/storage';
import { NgForm,Validators,ReactiveFormsModule,FormsModule } from '@angular/forms';
import { JoyrideModule } from 'ngx-joyride';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NewloginComponent } from './newlogin/newlogin.component';
import { AgGridModule } from 'ag-grid-angular';
import { IonicPullupModule } from 'ionic-pullup';


@NgModule({
  declarations: [AppComponent, NewloginComponent],
  entryComponents: [],
  imports: [
    BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule,
    IonicStorageModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    JoyrideModule.forRoot(),
    IonicPullupModule,
 
    AgGridModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    })
  ],
  providers: 
  [ ApiService,
    AuthGuard,
    DatePipe, CurrencyPipe,
    Settings,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
