
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastController, Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url: string = 'http://ghaya.mhds.co.in/index.php/';
  path: string = 'http://ghaya.mhds.co.in/';
  imagebasepath = 'http://ghaya.mhds.co.in/';

  constructor(private httpClient: HttpClient, private _Router: Router, private plt: Platform) {
  }

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };


  getStockdata(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }
    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }
    return this.httpClient.get(endpoint, reqOpts);
  }

  get(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }
    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }
    return this.httpClient.get(this.url + '/' + endpoint, reqOpts);
  }

  post(endpoint: string, body: any, reqOpts?: any) {
     return this.httpClient.post(this.url + endpoint, body, reqOpts);
    // console.log('this.networkService.getCurrentNetworkStatus()');
    // alert(this.networkService.getCurrentNetworkStatus());
    // if (false) {

    // if (this.plt.is('cordova')) {
    //   if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Online) {
    //     alert('status online');
    //     return this.httpClient.post(this.url + endpoint, body, reqOpts);
    //   }
    //   else {
    //     //alert('Sorry');
    //     alert('status Offline');
    //     this._Router.navigate(['/connectionmissing']);
    //   }
    // }
    // else {
    //   return this.httpClient.post(this.url + endpoint, body, reqOpts);
    // }
  }

  put(endpoint: string, body: any, reqOpts?: any) {
    return this.httpClient.put(this.url + '/' + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any) {
    return this.httpClient.delete(this.url + '/' + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.httpClient.patch(this.url + '/' + endpoint, body, reqOpts);
  }

  search(terms: Observable<string>) {
    return terms.pipe(debounceTime(500)
    ).pipe(distinctUntilChanged()).pipe(
      switchMap(term => this.searchEntries(term)));
  }
  
  
  searchEntries(term) {
    let selller: { searchstring: string } = {
        searchstring: term
      }
    return this.httpClient.post<any>(this.url + "Dashboardapi/searchwithstring", selller, this.httpOptions)
    //return this.httpClient.get<any>(this.coreservicepatienturl + "patient/patient/patientlist?searchtext=" + term + "&tenantid=" + tenantid);
  }

  getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
         console.log(resp);
          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          console.log(err);
          reject(err);
        });
    });

  }

  getAddress(latitude, longitude) {
    return new Promise(function (resolve, reject) {
        var request = new XMLHttpRequest();
        var method = 'GET';
        var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude + ',' + longitude + '&sensor=true&key=AIzaSyBWQzfg1cM3ZHvr-tPQ8zXlKOvXPSsSl98';
        var async = true;
        request.open(method, url, async);
        request.onreadystatechange = function () {
            if (request.readyState == 4) {
                if (request.status == 200) {
                    var data = JSON.parse(request.responseText);
                    console.log('data in get address');
                    console.log(data);
                    var address = data.results[0];
                    resolve(address);
                }
                else {
                    reject(request.status);
                }
            }
        };
        request.send();
    });
};

// Crud Operation
getAllData(): Observable<any> {
  
  return this.httpClient.get(`http://stock.mhds.co.in/getsharelist`);
}

addNewData(Data: any): Observable<any> {
  
  return this.httpClient.get('http://stock.mhds.co.in/insertshare/' + Data[0] + '/' + Data[1] + '/' + Data[2] + '/' + Data[3], Data);
}

deleteData(Data: any): Observable<any> {
  
  return this.httpClient.get('http://stock.mhds.co.in/deletestock/' + Data[1]);
}

updateData(id: any, Data: any) {
  
  return this.httpClient.get('http://stock.mhds.co.in/updateshare/' + Data[0] + '/' + Data[1] + '/' + Data[2] + '/' + Data[3], Data);
}
  
}
