import { Component } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(  private storage: Storage ,   private navCtrl: NavController,) {}

  
  logout() {
    this.storage.clear();
    this.navCtrl.navigateRoot('/signin');
    }

    
}


